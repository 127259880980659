import React, { useEffect, useRef, useState, useCallback } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import constant from "../../Components/Services/constant";
function CategoryHtml({ indexLoop, onParentData, dataObj }) {
    const didMountRef = useRef(true);
    const categoryRef = useRef(null);
    const mobilecatRef = useRef(null);
    const [loading, setLoading] = useState();
    const [homeCategoryData, setHomeCategoryData] = useState([]);
    const [setSession, SetSession] = useState("");
    const [baseUrl, setBaseUrl] = useState("");
    useEffect(() => {
        if (didMountRef.current) {
            SetSession(localStorage.getItem("USER_TOKEN"));
            getHomeCategoryData();
        }
        didMountRef.current = false;
    }, []);
    const getHomeCategoryData = () => {
        setLoading(true);
        const dataString = {
            data: dataObj,
        };
        ApiService.postData("home-category-data", dataString).then((res) => {
            if (res.status == "success") {
                setBaseUrl(res.baseUrl)
                setHomeCategoryData(res.resCategoryData);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    };
    const categhandlePrev = useCallback(() => {
        if (!categoryRef.current) return;
        categoryRef.current.swiper.slidePrev();
    }, []);

    const categhandleNext = useCallback(() => {
        if (!categoryRef.current) return;
        categoryRef.current.swiper.slideNext();
    }, [])
    const mobilecathandleNext = useCallback(() => {
        if (!categoryRef.current) return;
        categoryRef.current.swiper.slideNext();
    }, [])
    const mobilecathandlePrev = useCallback(() => {
        if (!categoryRef.current) return;
        categoryRef.current.swiper.slidePrev();
    }, []);
    return (
        <>
            {loading == true ? (
                <>
                    <BrowserView>
                        <div className="row g-2">
                            {[...Array(4)].map((_, index) => (
                                <div key={index} className="col-lg-3 col-6">
                                    <div className="product">
                                        <figure className="product-media">
                                            <Skeleton variant="text" width={300} height={250} />
                                        </figure>
                                        <div className="product-details">
                                            <h3 className="product-name">
                                                <Skeleton variant="text" width={150} />
                                            </h3>
                                            <div className="product-price">
                                                <Skeleton variant="text" width={150} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className="row g-2">
                            {[...Array(4)].map((_, index) => (
                                <div key={index} className="col-lg-3 col-6">
                                    <div className="product">
                                        <figure className="product-media">
                                            <Skeleton variant="text" width={300} height={160} />
                                        </figure>
                                        <div className="product-details">
                                            <h3 className="product-name">
                                                <Skeleton variant="text" width={150} />
                                            </h3>
                                            <div className="product-price">
                                                <Skeleton variant="text" width={150} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </MobileView>
                </>
            ) : (
                <>
                    <BrowserView>
                        {dataObj.home_display_type === 2 ? (
                            <>
                                {dataObj.home_product_name ? <div className="page-title mb-4">
                                    <h2 className="mb-0">{dataObj.home_product_name}</h2>
                                    {/* <a href={"/category"} className="viewmorebutton">View More <i className="ri-arrow-right-s-line ms-2"></i></a> */}
                                </div> : null}
                                <div className={"row g-3 " + dataObj.home_col_grid + dataObj.home_column}>
                                    {homeCategoryData.map((subvalue, indexProduct) => {
                                        return (
                                            <div className="category overlay-zoom">
                                            <a href={"/collection/category/" + subvalue.cat_slug} key={indexProduct}>
                                                <figure className="category-media">
                                                <img src={subvalue.cat_image != null ? baseUrl + subvalue.cat_image : constant.DEFAULT_IMAGE} alt={subvalue.cat_name} width={20}></img>
                                                </figure>
                                                <div className="category-name">{subvalue.cat_name}</div>                                               
                                            </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : (
                            <>
                                {dataObj.home_product_name ? <div className="page-title mb-4">
                                    <h2 className="mb-0">{dataObj.home_product_name}</h2>
                                    <a href={"/category"} className="viewmorebutton">View More <i className="ri-arrow-right-s-line ms-2"></i></a>

                                </div> : null}
                                <Swiper
                                    loop={true}
                                    spaceBetween={15}
                                    navigation={{
                                        nextEl: ".swiper-button-next",
                                        prevEl: ".swiper-button-prev",
                                    }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                        600: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                        1000: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                    }}
                                    ref={categoryRef}
                                >
                                    {homeCategoryData.map((subvalue, indexProduct) => {
                                        return (
                                            <SwiperSlide key={indexProduct}>
                                                <div className="category overlay-zoom">
                                            <a href={"/collection/category/" + subvalue.cat_slug} key={indexProduct}>
                                                <figure className="category-media">
                                                <img src={subvalue.cat_image != null ? baseUrl + subvalue.cat_image : constant.DEFAULT_IMAGE} alt={subvalue.cat_name} width={20}></img>
                                                </figure>
                                                <div className="category-name">{subvalue.cat_name}</div>                                               
                                            </a>
                                            </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                                <div className='swiper-buttons__wrapper'>
                                    <div className='container'>
                                        <div className='swiper-buttons__box'>
                                            <div className="swiper-button swiper-button-prev" style={{ alignItems: 'center' }} onClick={categhandlePrev} >
                                                <span><svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 14L26 14" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                    <path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                </svg>
                                                </span>
                                            </div>
                                            <div className="swiper-button swiper-button-next" onClick={categhandleNext} style={{ alignItems: 'center' }}>
                                                <span><svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 14L26 14" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                    <path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </BrowserView>
                    <MobileView>
                        {dataObj.home_display_type === 2 ? (
                            <>
                                {dataObj.home_product_name ? <div className="page-title mb-4">
                                    <h2 className="mb-0">{dataObj.home_product_name}</h2>
                                    <a href={"/category"} className="viewmorebutton">View More <i className="ri-arrow-right-s-line ms-2"></i></a>

                                </div> : null}
                                <div className={"row g-3 " + dataObj.home_col_grid + dataObj.home_column}>
                                    {homeCategoryData.map((subvalue, indexProduct) => {
                                        return (
                                            <div className="category overlay-zoom">
                                            <a href={"/collection/category/" + subvalue.cat_slug} key={indexProduct}>
                                                <figure className="category-media">
                                                <img src={subvalue.cat_image != null ? baseUrl + subvalue.cat_image : constant.DEFAULT_IMAGE} alt={subvalue.cat_name} width={20}></img>
                                                </figure>
                                                <div className="category-name">{subvalue.cat_name}</div>                                               
                                            </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : (
                            <>
                                {dataObj.home_product_name ? <div className="page-title mb-4">
                                    <h2 className="mb-0">{dataObj.home_product_name}</h2>
                                    <a href={"/category"} className="viewmorebutton">View More <i className="ri-arrow-right-s-line ms-2"></i></a>

                                </div> : null}
                                <Swiper
                                    loop={true}
                                    spaceBetween={15}
                                    navigation={{
                                        nextEl: ".swiper-button-next",
                                        prevEl: ".swiper-button-prev",
                                    }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                        600: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                        1000: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                    }}
                                    ref={mobilecatRef}
                                >
                                    {homeCategoryData.map((subvalue, indexProduct) => {
                                        return (
                                            <SwiperSlide key={indexProduct}>
                                                <div className="category overlay-zoom">
                                            <a href={"/collection/category/" + subvalue.cat_slug} key={indexProduct}>
                                                <figure className="category-media">
                                                <img src={subvalue.cat_image != null ? baseUrl + subvalue.cat_image : constant.DEFAULT_IMAGE} alt={subvalue.cat_name} width={20}></img>
                                                </figure>
                                                <div className="category-name">{subvalue.cat_name}</div>                                               
                                            </a>
                                            </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                                <div className='swiper-buttons__wrapper'>
                                    <div className='container'>
                                        <div className='swiper-buttons__box'>
                                            <div className="swiper-button swiper-button-prev" style={{ alignItems: 'center' }} onClick={mobilecathandlePrev} >
                                                <span><svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 14L26 14" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                    <path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                </svg>
                                                </span>
                                            </div>
                                            <div className="swiper-button swiper-button-next" onClick={mobilecathandleNext} style={{ alignItems: 'center' }}>
                                                <span><svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 14L26 14" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                    <path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </MobileView>
                </>
            )}
        </>
    );
}
export default CategoryHtml;
