import React, { useEffect, useRef, useState, useContext } from 'react';
import LoginModal from '../Modals/login_modal';
import CartModal from '../Modals/cart_modal';
import { ApiService } from "../../Components/Services/apiservices";
import { BrowserView, MobileView } from 'react-device-detect';
import DataContext from '../Elements/eventContext';
import QuickViewModal from '../Modals/quick_view_modal';
import { useNavigate } from "react-router-dom";
import sessionCartData from '../Elements/cart_session_data';
import VariationModal from '../Modals/variation.modal';
import HeaderMenu from '../Elements/header_menu';
import MenuModal from '../Modals/menu_modal';
import { Modal } from 'react-bootstrap';

function Header() {
    const dataArray = sessionCartData();
    const contextValues = useContext(DataContext);
    const didMountRef = useRef(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [deliveryLocationData, setDeliveryLocationData] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length >= 2) {
                fetchSearchResults();
            }
        }, 300);
        contextValues.setAddressSession(dataArray[0])
        contextValues.setCartSessionData(dataArray[1])
        contextValues.setCouponSession(dataArray[2])
        contextValues.setCartSummary(dataArray[3])
        contextValues.setCartCount(dataArray[1].length)
        contextValues.setUserToken(dataArray[5])
        const storedLocation = JSON.parse(localStorage.getItem("DELIVERY_LOCATION"));
        contextValues.setDeliveryLocationData(storedLocation)
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const fetchSearchResults = () => {
        const dataString = {
            query: searchTerm,
        };
        ApiService.postData("get-search-data", dataString).then((res) => {
            if (res.status === "success") {
                setSearchResults(res.data);
            } else {
            }
        });
    };
    const wrapperRef = useRef(null);
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setSearchResults([]);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (didMountRef.current) {

            getsettingData();

        }
        didMountRef.current = false;
    })
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/search')
    }
    const getsettingData = () => {
        ApiService.fetchData("settingsdata").then((res) => {
            if (res.status === "success") {
                contextValues.setSettingData(res.sitesettings)
                contextValues.setSettingImageBaseUrl(res.setting_image_path);
            }
        });
    };
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }


    const loginModal = () => {
        contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
    }
    const menuModal = () => {
        contextValues.setToggleMenuModal(!contextValues.toggleMenuModal)
      }
          const registerModal = () => {
        contextValues.setToggleRegisterModal(!contextValues.toggleRegisterModal)
    }

    return (
        <>
            <BrowserView>
                <header className='header'>
                    {contextValues.settingData.header_top ? <div className='headerTop'><marquee>{contextValues.settingData.header_top}</marquee></div> : ""}
                    <div className='headerMiddle'>
                        <div className='container'>
                            <div className='header-left'>
                                <a className='logo' href='/'><img src='/images/logo.png' alt='' width='175' height='88'></img></a>

                                <div className='header-search'>
                                    <form action="#" className="input-wrapper">
                                        <input type='text'
                                            name="search"
                                            autoComplete="off"
                                            placeholder='Search for products, brands & more...'
                                            onChange={handleInputChange}
                                            value={searchTerm}
                                        />
                                        {searchResults &&
                                            searchResults.length > 0 &&
                                            searchTerm.trim() !== "" ? (
                                            <div className="header-search-list" id="search_input">
                                                <ul>
                                                    {searchResults.map((value, index) => (
                                                        <a href={value.redirect}>
                                                            <li key={index}>{value.name}</li>
                                                        </a>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        <button className='btn btn-search' type='button'>  <i className="ri-search-line"></i></button>
                                    </form>
                                </div>
                            </div>
                            <div className='header-right justify-content-end'>
                                <ul className='header-right-list'>

                                    {contextValues.userToken ? (
                                        <>
                                            <li><a href="/account/wishlist"><i class="ri-heart-3-line"></i><span>Favorites</span></a></li>
                                            <li><a href="/account/account-overview"><i className="ri-user-line"></i> <span>Account</span></a>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li><a href="javascript:void(0)" onClick={(e) => loginModal()}><i class="ri-heart-3-line"></i><span>Favorites</span></a></li>
                                            <li><a href="javascript:;" onClick={(e) => loginModal()}><i className="ri-user-line"></i> <span>Account</span></a></li>
                                        </>
                                    )}

                                    <li className='cart-toggle'><a href="javascript:;" onClick={(e) => cartModal()}><i className="ri-shopping-bag-3-line"></i> <span>My Cart</span><div className="cart-count">{contextValues.cartCount}</div></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <HeaderMenu />
                </header>

            </BrowserView>
            <MobileView>
                <header className='mheader'>
                    {contextValues.settingData.header_top ? <div className='headerTop'><marquee>{contextValues.settingData.header_top}</marquee></div> : ""}
                    <div className='mheaderTop'>
                        <div className='container'>
                            <div className='mheaderLeft'>
                                <a href='#' className='sideToggle'
                                 onClick={(e) => menuModal()}
                                ><i class="ri-menu-2-fill"></i></a>
                                <a className='logo' href='/'><img src='/images/logo.png' alt='' width='100' height='50'></img></a>
                            </div>
                            <div className='mheaderRight'>
                                <a href="javascript:void(0)" className="mcarttoggle" onClick={(e) => cartModal()}><i className="ri-shopping-bag-3-line ri-2x"></i><div className="cart-count">{contextValues.cartCount}</div></a>
                            </div>
                        </div>
                    </div>
                    <div className='mheaderBottom'>
                        <div className='container'>
                            <div className="mheader-search"
                                onClick={handleClick}><i className="ri-search-line ri-xl me-3"></i>
                                Search for products, brands & more... </div>
                        </div>
                        {/* {searchClicked && (
                            <div className='searchbar'>
                                <input placeholder='Search for dry fruits, walnuts, more...' />
                                <button className='searchClose' type='button' onClick={() => setSearchClicked(false)}><i className="ri-close-line ri-2x"></i></button>
                            </div>
                        )} */}
                    </div>

                </header>

            </MobileView>
            {show && <LoginModal show={show} setShow={setShow} handleClose={handleClose} handleShow={handleShow}></LoginModal>}
            <LoginModal />
            <CartModal />
            {/* {contextValues.toggleCartModal ? <CartModal /> : null } */}
            {contextValues.toggleQuickViewModal && <> <QuickViewModal /></>}
            <Modal show={contextValues.toggleMenuModal} onHide={(e) => menuModal()} className="left menu-modal">
                {contextValues.toggleMenuModal && (<MenuModal />)}
            </Modal>

            {contextValues.toggleVariationModal && <> <VariationModal /></>}
        </>
    )
}
export default Header