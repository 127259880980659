import React, { useEffect, useRef, useState, useContext } from 'react';
import DataContext from '../Elements/eventContext'
import Loader from "react-js-loader";
import { ApiService } from '../Services/apiservices';
import sessionCartData from '../Elements/cart_session_data';
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import constant from '../Services/constant';
import GoogleMapReact from 'google-map-react';
import { showToast } from '../Elements/toastUtlis';
import Modal from 'react-bootstrap/Modal';
const AnyReactComponent = ({ imageSrc }) => (
    <div>
        <img src={imageSrc} alt="Location Marker" style={{ width: '40px', height: '40px' }} />
    </div>
);
function CheckoutAddressModal() {
    const didMountRef = useRef(true);
    const contextValues = useContext(DataContext);
    const [openAddressSearchBox, setOpenAddressSearchBox] = useState(true);
    const [saveAddressLoader, setSaveAddressLoader] = useState(false);
    const { suggestions: { status, data }, setValue, } = usePlacesAutocomplete({ requestOptions: { componentRestrictions: { country: 'IN' } }, });
    //const [newAddress, setNewAddress] = useState({ ua_id: 0, ua_house_no: '', ua_area: '', ua_address_type: 'Home', ua_name: '', ua_email: '', ua_address_type_other: '' });
    const [alertError, setAlertError] = useState("");
    useEffect(() => {
        //if (didMountRef.current) {
        /* const storedLocation = JSON.parse(localStorage.getItem("DELIVERY_LOCATION"));
        if (storedLocation && storedLocation.user_lng) {
            setDeliveryLocationData(storedLocation);
        } */
        //}
        //didMountRef.current = false;
    }, [contextValues]);
    const checkoutAddressModal = () => {
        contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
    }
    const openAddressSearch = () => {
        setOpenAddressSearchBox(false)
    }

    const handleSelect = async (value) => {
        try {
            setValue([])
            const results = await getGeocode({ address: value });
            const { lat, lng } = await getLatLng(results[0]);
            geocodelocation(lat, lng);
        } catch (error) {
            //setLocationError(error);
        }
    };

    const geocodelocation = async (lat, lng) => {
        const mapApiUrl = `geocode/json?latlng=${lat},${lng}&key=${constant.GOOGLE_MAP_KEY}`;
        try {
            contextValues.setSpinnerCubLoader(1)
            const response = await ApiService.fetchGoogleData(mapApiUrl);
            if (response.status === "OK") {
                const updateAddressObj = {
                    full_address: response.results[0].formatted_address,
                    city: "",
                    state: "",
                    country: "",
                    postal_code: "",
                    user_lat: response.results[0].geometry.location.lat,
                    user_lng: response.results[0].geometry.location.lng,
                };

                response.results[0].address_components.forEach((component) => {
                    component.types.forEach((type) => {
                        switch (type) {
                            case "locality":
                                updateAddressObj.city = component.long_name;
                                break;
                            case "administrative_area_level_1":
                                updateAddressObj.state = component.long_name;
                                break;
                            case "country":
                                updateAddressObj.country = component.long_name;
                                break;
                            case "postal_code":
                                updateAddressObj.postal_code = component.long_name;
                                break;
                            default:
                                break;
                        }
                    });
                });
                contextValues.setDeliveryLocationData(updateAddressObj)
                setOpenAddressSearchBox(true)
                contextValues.setSpinnerCubLoader(0)
            }
        } catch (error) {
            contextValues.setSpinnerCubLoader(0)
        }
    }
    const selectAddresType = (type) => {
        contextValues.setNewAddress((prevState) => ({
            ...prevState,
            ua_address_type: type
        }));
    }
    const onTodoChange = (e) => {
        const { name, value } = e.target;
        contextValues.setNewAddress((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const saveAddress = () => {
        if (contextValues.newAddress.ua_name == "" || contextValues.newAddress.ua_name == null) {
            setAlertError('Please Enter Full Name');
            return
        }
        if (contextValues.newAddress.ua_email == "" || contextValues.newAddress.ua_email == null) {
            setAlertError('Please Enter Email Address');
            return
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(contextValues.newAddress.ua_email)) {
            setAlertError('Please Enter a Valid Email Address');
            return;
        }
        if (contextValues.newAddress.ua_house_no == "" || contextValues.newAddress.ua_house_no == null) {
            setAlertError('Please Enter Office / Building Name');
            return
        }
        if (contextValues.newAddress.ua_area == "" || contextValues.newAddress.ua_area == null) {
            setAlertError('Please Enter Area / Street / Sector / Village');
            return
        }
        if (contextValues.newAddress.ua_address_type == 'Other' && (contextValues.newAddress.ua_address_type_other == "" || contextValues.newAddress.ua_address_type_other == null)) {
            setAlertError('Please Enter Other');
            return
        }
        const productData = {
            user_address: contextValues.newAddress,
            locationData: contextValues.deliveryLocationData,
        };
        setSaveAddressLoader(true)
        ApiService.postData("userAddressProcess", productData).then((res) => {
            if (res.status === 'success') {
                //localStorage.setItem("ADDRESS_SESSION", JSON.stringify(res.userAddress));
                //contextValues.setUserAddressList(res.resUserAddress);
                //contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
                //setSaveAddressLoader(false)
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } else {
                setAlertError(res.message);
                setSaveAddressLoader(false);
            }
        }).catch((error) => {
            setAlertError(error);
            setSaveAddressLoader(false);
        });
    }
    return (
        <>
            <Modal className='addressModal' show={contextValues.toggleCheckoutAddressModal} onHide={(e) => checkoutAddressModal()} backdrop={contextValues.userAddressList.length > 0 ? true : "static"}>
                <div className='addresssec'>
                    <div className='addresssecHeader'>
                        <h5 className='mb-0'>Add Address</h5>
                        {contextValues.userAddressList.length > 0 && 
                        <button type='button' className='btnClose' onClick={(e) => checkoutAddressModal()}> <i className="ri-close-line ri-2x"></i></button>}
                    </div>
                    <div style={{ height: '200px', width: '100%' }}>
                        {contextValues.spinnerCubLoader > 0 ? <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} /> :
                            {/* <GoogleMapReact
                                bootstrapURLKeys={{ key: constant.GOOGLE_MAP_KEY }}
                                defaultCenter={{ lat: contextValues.deliveryLocationData.user_lat, lng: contextValues.deliveryLocationData.user_lng }}
                                defaultZoom={17}
                                options={{
                                    zoomControl: false,
                                    panControl: false,
                                    fullscreenControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                }}
                                draggable={false}
                            >
                                <AnyReactComponent
                                    lat={contextValues.deliveryLocationData.user_lat}
                                    lng={contextValues.deliveryLocationData.user_lng}
                                    imageSrc="/images/location-pin.png"
                                />
                            </GoogleMapReact> */}
                            }
                    </div>
                    <div className='addresssecBody'>
                        <div className='row g-3'>
                            {openAddressSearchBox ?
                                <>
                                    <div className='col-lg-12'>
                                        <div className='ckboxbo'>
                                            <div className='ckboxbo-inner'>
                                                <div className='ckboxbo-icon'>
                                                    <i className="ri-map-pin-line ri-xl mr-10"></i>
                                                    {/* <span className='currentaddress'>{contextValues.deliveryLocationData.full_address}</span> */}
                                                </div>
                                                <div className='ckboxbo-itemscount' onClick={(e) => openAddressSearch()}> <i className="ri-edit-box-line ri-lg ml-5"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    {alertError && (<div className="alert alert-danger" role="alert">{alertError}</div>)}
                                    <div className='col-lg-12'>
                                        <div className='form-group'>
                                            <input type='text' placeholder='Full Name *' name="ua_name" onChange={(e) => onTodoChange(e)} value={contextValues.newAddress.ua_name}></input>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='form-group'>
                                            <input type='email' placeholder='Email Address *' name="ua_email" onChange={(e) => onTodoChange(e)} value={contextValues.newAddress.ua_email}></input>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='form-group'>
                                            <input type='text' placeholder='Office / Building Name *' name="ua_house_no" onChange={(e) => onTodoChange(e)} value={contextValues.newAddress.ua_house_no}></input>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='form-group'>
                                            <input type='text' placeholder='Area / Street / Sector / Village *' name="ua_area" onChange={(e) => onTodoChange(e)} value={contextValues.newAddress.ua_area}></input>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='tx-14 fw600 mb-2'>Save address as*</div>
                                        <div className='saveaddresslist'>
                                            <ul>
                                                <li className={`${contextValues.newAddress.ua_address_type == 'Home' ? 'active' : ''}`} onClick={(e) => selectAddresType('Home')}>Home</li>
                                                <li className={`${contextValues.newAddress.ua_address_type == 'Work' ? 'active' : ''}`} onClick={(e) => selectAddresType('Work')}>Work</li>
                                                <li className={`${contextValues.newAddress.ua_address_type == 'Other' ? 'active' : ''}`} onClick={(e) => selectAddresType('Other')}>Other</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {contextValues.newAddress.ua_address_type == 'Other' &&
                                        <div className='col-lg-12'>
                                            <div className='form-group'>
                                                <input type='text' placeholder='Other *' name="ua_address_type_other" onChange={(e) => onTodoChange(e)} value={contextValues.newAddress.ua_address_type_other}></input>
                                            </div>
                                        </div>
                                    }
                                    <div className='col-lg-12'>
                                        <button type="button" className="btn btn-primary btn-full btn-md ckbutton  mt-2" onClick={(e) => saveAddress()} disabled={saveAddressLoader} >{saveAddressLoader ? <img src="/images/loder01.gif" width="60px" height="11px" /> : 'Save & Continue'}</button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='col-lg-12'>
                                        <div className='form-group'>
                                            <div className='lcsearch'>
                                                <div className='lcsearchinput'>
                                                    <input type="text" placeholder="Search Delivery Location..."
                                                        onChange={(e) => { setValue(e.target.value); }} />
                                                </div>
                                                <div className='lcsearcharea'>
                                                    <ul>
                                                        {status === "OK" && data.map(({ id, description }) => (
                                                            <li key={id} onClick={() => handleSelect(description)}>
                                                                {description}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <button type="button" className="btn btn-primary btn-full btn-md ckbutton  mt-2" onClick={(e) => setOpenAddressSearchBox(true)}>Back</button>
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CheckoutAddressModal