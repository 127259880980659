import { useEffect, useRef, useState, useContext } from 'react';
import DataContext from '../Elements/eventContext';
import { Alert, Modal } from 'react-bootstrap';
import { ApiService } from '../Services/apiservices';
import { validEmail, validNumber } from '../Elements/Regex';
import OTPInput from 'react-otp-input';

function LoginModal() {
    const { settingData, settingImageBaseUrl } = useContext(DataContext);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const didMountRef = useRef(true)
    const [steps, setSteps] = useState(1);
    const [spinnerLoading, setspinnerLoading] = useState(false);
    const [resendTimer, setResendTimer] = useState(30);
    const inputRef = useRef(null);
    const [regDetails, setRegDetails] = useState({
        user_fname: "",
        user_email: "",
    });

    const [otp, setOtp] = useState('');

    const [loginusermobile, setLoginusermobile] = useState('');

    const onChangeOtp = (otp) => {
        setErrorMessage("");
        setSuccessMessage('')
        setOtp(otp);
        if (otp.length === 4) {
            userLoginOtpProcess(otp);
        }
    };

    const contextValues = useContext(DataContext)

    const userLoginprocess = () => {
        if (loginusermobile === '') {
            if (inputRef.current) {
                inputRef.current.style.border = '1px solid red';
            }
            return false;
        }
        if (!validNumber.test(loginusermobile)) {
            setErrorMessage("Please enter valid Mobile Number");
            return false;
        }
        setIsLoading(true)
        const dataString = {
            user_mobile: loginusermobile
        }
        ApiService.loginProccessPostData("user-login-process", dataString).then((res) => {
            if (res.status === "success") {
                localStorage.setItem("TEMP_USER_TOKEN", res.user_token);
                setErrorMessage('')
                setSteps(2);
                setIsLoading(false)
                setResendTimer(30);
            } else {
                setErrorMessage(res.message);
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }


    const userLoginOtpProcess = (otp) => {
        setspinnerLoading(true)
        const dataString = {
            user_otp: otp,
        };
        ApiService.loginProccessPostData("otpverify", dataString).then((res) => {
            if (res.status === "success") {
                if (res.user_status === 'new_register') {
                    setTimeout(() => {
                        localStorage.setItem("TEMP_USER_TOKEN", res.user_token);
                        setSteps(3)
                        setspinnerLoading(false)
                    }, 500);
                } else if (res.user_status == 'already_registered') {
                    localStorage.setItem("USER_TOKEN", res.user_token);
                    setSuccessMessage(res.message)
                    setspinnerLoading(false)
                    setTimeout(() => {
                        setSuccessMessage('')
                        window.location.reload();
                    }, 500);
                }
            }
            else if (res.status == 'error' && res.message == 'Session expired') {
                setErrorMessage(res.message)
                setspinnerLoading(false)
                setTimeout(() => {
                    setErrorMessage('')
                    localStorage.removeItem("TEMP_USER_TOKEN");
                    window.location.reload();
                }, 500);
            }
            else {
                setTimeout(() => {
                    setErrorMessage(res.message);
                    setspinnerLoading(false)
                }, 500);
            }
        }).catch(() => {
            setspinnerLoading(false)
        });

    };

    useEffect(() => {
        const timerId = setInterval(() => {
            if (resendTimer > 0) {
                setResendTimer(prev => prev - 1);
            }
        }, 1000);
        return () => {
            clearInterval(timerId);
        };
    }, [resendTimer]);

    const resendOTP = () => {
        setErrorMessage("");
        setResendTimer(30);
        setOtp("");
        setspinnerLoading(true)
        const dataString = {
            user_token: '',
        }
        ApiService.loginProccessPostData('resendotp', dataString).then((res) => {
            if (res.status === "success") {
                setTimeout(() => {
                    setspinnerLoading(false);
                }, 200);
            } else if (res.status == 'error' && res.message == 'Session expired') {
                setErrorMessage(res.message);
                setTimeout(() => {
                    setErrorMessage('')
                    localStorage.removeItem("TEMP_USER_TOKEN");
                    window.location.reload();
                }, 500);
            }
            else {
                setErrorMessage(res.message);
                setTimeout(() => {
                    setspinnerLoading(false);
                }, 500);
            }
        }).catch(() => {
            setspinnerLoading(false)
        });
    }

    const onSignUpInputChange = (e) => {

        setErrorMessage('')
        setSuccessMessage('')
        const { name, value } = e.target;
        setRegDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };

    const userRegisterProcess = (e) => {
        let counter = 0;
        const myElements = document.getElementsByClassName('registerrequired');
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter == 0) {

            if (!validEmail.test(regDetails.user_email)) {
                setErrorMessage("Please Enter Valid Email");
                return false;
            }

            setIsLoading(true)
            ApiService.loginProccessPostData("user-register-process", regDetails).then((res) => {
                if (res.status === "success") {
                    localStorage.removeItem("TEMP_USER_TOKEN");
                    setSuccessMessage(res.message)
                    localStorage.setItem("USER_TOKEN", res.user_token);
                    contextValues.setUserToken(res.user_token)
                    setTimeout(() => {
                        setSuccessMessage('')
                        window.location.reload();
                    }, 500);
                }
                else if (res.status == 'error' && res.message == 'Session expired') {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        localStorage.removeItem("TEMP_USER_TOKEN");
                        window.location.reload();
                    }, 500);
                }
                else {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500);
                }
            }).catch(() => {
                setIsLoading(false)
            });
        }

    }
    const onLoginInput = (e) => {
        setLoginusermobile(e.target.value)
        if (e.target.value !== '') {
            e.target.style.border = '';
        }
        setErrorMessage('')
    }

    const goBack = () => {
        setErrorMessage("");
        setSuccessMessage('')
        setLoginusermobile('')
        setRegDetails({
            user_fname: "",
            user_email: "",
        })
        setOtp("");
        setSteps(1);
    }

    const loginModal = () => {
        contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
    }
    return (

        <Modal show={contextValues.toggleLoginModal} onHide={(e) => loginModal()} className='loginModal'>
            <div class="loginContant">
                <button type="button" className="btn-close" onClick={(e) => loginModal()}></button>
                {steps == 1 && (
                    <>
                        <div className='text-center'>
                            <img src={settingImageBaseUrl + settingData.logo} className='mb-20' alt="" width="175" height="88" />
                            <h5 className='fw700'>Log in or Sign up</h5>
                            <p className='tx-gray'>For Better Experience, Order tracking & Regular updates</p>
                        </div>
                        <div className='form-group country-input mb-15'>
                            <input
                                type="number"
                                name="loginusermobile"
                                ref={inputRef}
                                min={0}
                                onChange={(e) => { onLoginInput(e) }}
                                placeholder="Enter Mobile Number"/>
                            <span className='country-code'>+91</span>
                        </div>
                        <div className='form-group mb-15'>
                        <button className="btn btn-primary btn-full" onClick={(e) => userLoginprocess()}>{isLoading ? (<img src="/images/loder01.gif" width="60px" height="11px" />) : ("Login")}</button>
                        </div>
                        <p className='text-center tx-12'>By continuing, you agree to our <a href='/terms-of-services' className='tx-primary' target='new'>Terms of Service</a> & <a href='/privacy-policy' className='tx-primary' target='new'>Privacy policy</a></p>
                    </>
                )}
                {steps == 2 && (
                    <>

                        <div className='text-center'>
                            <h5 className='fw700'>OTP Verification</h5>
                            <p className='tx-gray'>We have sent a verification code to {loginusermobile} <a href='javascript:;' className='tx-primary' onClick={(e) => goBack()}>Change</a></p>
                        </div>
                        {errorMessage && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        {successMessage && (
                            <div className="alert alert-success" role="alert">
                                {successMessage}
                            </div>
                        )}
                        <div className='form-group mb-15 otp-input'>
                        <OTPInput
                                value={otp}
                                onChange={onChangeOtp}
                                numInputs={4}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>
                        {resendTimer === 0 ? (
                            <p className='tx-gray text-center'>
                                Did not receive OTP? <a href='javascript:;' className='tx-primary' onClick={(e) => resendOTP()}>Resend OTP</a>
                            </p>
                        ) : (
                            <h6 className='tx-gray text-center'>Resend code in {resendTimer} sec</h6>
                        )}
                        {spinnerLoading && (
                            <div className="siteloader">
                                <img src="/images/loader.webp" alt="Loading..." width="30" height="30" />
                            </div>
                        )}
                    </>
                )}

               {steps == 3 && (
                    <>
                        <div className='text-center'>
                            <h5 className='fw700'>Complete Your Registration</h5>
                        </div>
                        <div className="form-group mb-15">
                            <input
                                type="text"
                                name="user_fname"
                                className="form-control registerRequired"
                                value={regDetails.user_fname}
                                onChange={(e) => onSignUpInputChange(e)}
                                placeholder="Full Name"
                            />
                        </div>
                        <div className="form-group mb-15">
                            <input
                                type="text"
                                name="user_email"
                                className="form-control registerRequired"
                                value={regDetails.user_email}
                                onChange={(e) => onSignUpInputChange(e)}
                                placeholder="Email Address"
                            />
                        </div>
                        <button className='btn btn-primary btn-full btn-md' type='button' onClick={(e) => userRegisterProcess()} >
                            {isLoading ? (
                                <img src="/images/loder01.gif" width="60px" height="11px" />
                            ) : (
                                "Register"
                            )}
                        </button>
                    </>
                )} 
            </div>
        </Modal>

    )
}

export default LoginModal