import React, { useEffect, useRef, useState, useContext } from 'react'
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from '../../Components/Services/apiservices'
import { useParams } from "react-router-dom";
import DataContext from '../../Components/Elements/eventContext';
import sessionCartData from '../../Components/Elements/cart_session_data';
import multiCurrency from '../../Components/Elements/multi_currrency';
import CouponModal from '../../Components/Modals/coupon_modal';
import PaymentModal from '../../Components/Modals/payment_modal';
import CheckoutAddressModal from '../../Components/Modals/checkout_address_modal';
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
function Checkout() {
  const didMountRef = useRef(true);
  const { orderId } = useParams();
  const [Razorpay] = useRazorpay();
  const contextValues = useContext(DataContext);
  const navigate = useNavigate();
  const [proccessLoader, setProccessLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("COD");
  const [couponsCount, setCouponsCount] = useState(0);
  const [spinnerLoading, setspinnerLoading] = useState(false);
  const [addressToggleCollaps, setAddressToggleCollaps] = useState(false);
  const [selectAddresActive, setSelectAddresActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (didMountRef.current) {
      getUserAddress()
      couponsListData()
      const dataArray = sessionCartData();
      contextValues.setAddressSession(dataArray[0])
      contextValues.setCartSessionData(dataArray[1])
      contextValues.setCouponSession(dataArray[2])
      contextValues.setCartSummary(dataArray[3])
      contextValues.setCartCount(dataArray[1].length)
      contextValues.setUserToken(dataArray[5])
      const storedLocation = JSON.parse(localStorage.getItem("DELIVERY_LOCATION"));
      contextValues.setDeliveryLocationData(storedLocation)
    }
    didMountRef.current = false;
  }, [contextValues]);

  const selectAddress = (addressValue) => {
    setSelectAddresActive(addressValue.ua_id)
    localStorage.setItem("ADDRESS_SESSION", JSON.stringify(addressValue));
    const addressSession = localStorage.getItem("ADDRESS_SESSION");
    const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
    contextValues.setAddressSession(parsedAddressSession)
    setAddressToggleCollaps(!addressToggleCollaps)
    /*  const productData = {
    addressData: addressValue,
    ua_lat: addressValue.ua_lat,
    ua_lng: addressValue.ua_lng,
    };
    ApiService.postData("checkServiceable", productData).then((res) => {
    if (res.status === 'success') {
    setSelectAddresActive(addressValue.ua_id)
    localStorage.setItem("ADDRESS_SESSION", JSON.stringify(addressValue));
    const addressSession = localStorage.getItem("ADDRESS_SESSION");
    const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
    contextValues.setAddressSession(parsedAddressSession)
    getShippingCharges();
    } else {
    setErrorMessage(res.message)
    setTimeout(() => {
    setErrorMessage('')
    }, 2000);
    }
    }).catch((error) => {
    
    }); */
  }

  const getShippingCharges = () => {
    const addressSession = localStorage.getItem("ADDRESS_SESSION");
    const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
    const productData = {
      itemtotal: contextValues.cartSummary && contextValues.cartSummary.mrpTotal ? contextValues.cartSummary.mrpTotal : 0,
      ua_id: parsedAddressSession.ua_id,
      cart_data: contextValues.cartSessionData,
    };
    ApiService.postData("calculateShippingAmount", productData).then((res) => {
      if (res.status === 'success') {
        contextValues.setShippingAmount(res.shipping_amount)
        setTimeout(() => {
          if (paymentMethod === 'COD') {
            cashOnDelivery(res.shipping_amount)
          } else {
            createTempOrder(paymentMethod, res.shipping_amount)
          }
        }, 500);
      } else {
        setErrorMessage(res.message)
        setTimeout(() => {
          setErrorMessage('')
          setProccessLoader(false)
        }, 2000);
      }
    }).catch((error) => {

    });
  }

  const couponsListData = () => {
    const productData = {
      sessionItemsSummary: []
    };
    ApiService.postData("couponsList", productData).then((res) => {
      if (res.status === 'success') {
        setCouponsCount(res.resCouponsData.length)
        setTimeout(() => {
          contextValues.setSpinnerCubLoader(0)
        }, 500);
      } else {
        setTimeout(() => {
          contextValues.setSpinnerCubLoader(0)
        }, 500);
      }
    }).catch((error) => {
      setTimeout(() => {
        contextValues.setSpinnerCubLoader(0)
      }, 500);
    });
  }

  const getUserAddress = () => {
    ApiService.fetchData("get-user-address").then((res) => {
      if (res.status === "success") {
        contextValues.setUserAddressList(res.resUserAddress);
        if (res.resUserAddress.length == 0) {
          checkoutAddressModal()
        } else {
          setSelectAddresActive(res.resUserAddress[0].ua_id)
          localStorage.setItem("ADDRESS_SESSION", JSON.stringify(res.resUserAddress[0]));
          const addressSession = localStorage.getItem("ADDRESS_SESSION");
          const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
          contextValues.setAddressSession(parsedAddressSession)
        }
        setspinnerLoading(false);
      } else {
        setspinnerLoading(false)
      }
    });
  };

  const removeCoupon = () => {
    localStorage.removeItem("COUPON_SESSION");
    const couponSession = localStorage.getItem("COUPON_SESSION");
    const parsedCouponSession = couponSession ? JSON.parse(couponSession) : {
      discount_amount: 0.0,
      promo_id: 0,
      promo_code: "",
    };
    contextValues.setCouponSession(parsedCouponSession)
  }

  const couponModal = () => {
    contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
  }

  const toggleAddressCollapsModal = () => {
    setAddressToggleCollaps(!addressToggleCollaps)
  }



  const checkoutAddressModal = () => {
    getUserAddress()
    contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
  }
  const proccessToPayment = () => {
    if (selectAddresActive > 0) {
      selectAddress(contextValues.addressSession)
      const productData = {
        addressData: contextValues.addressSession,
        ua_lat: contextValues.addressSession.ua_lat,
        ua_lng: contextValues.addressSession.ua_lng,
      };
      setProccessLoader(true)
      ApiService.postData("checkServiceable", productData).then((res) => {
        if (res.status === 'success') {
          setSelectAddresActive(contextValues.addressSession.ua_id)
          localStorage.setItem("ADDRESS_SESSION", JSON.stringify(contextValues.addressSession));
          const addressSession = localStorage.getItem("ADDRESS_SESSION");
          const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
          contextValues.setAddressSession(parsedAddressSession)
          getShippingCharges();
        } else {
          setErrorMessage(res.message)
          setTimeout(() => {
            setErrorMessage('')
            setProccessLoader(false)
          }, 2000);
        }
      }).catch((error) => {

      });
    } else if (contextValues.userAddressList.length == 0) {
      setErrorMessage('Please add address')
      setTimeout(() => {
        setErrorMessage('')
      }, 2000);
    } else {
      setErrorMessage('Please select address')
      setTimeout(() => {
        setErrorMessage('')
      }, 2000);
    }
  }

  const selectPaymentMethod = (type) => {
    setPaymentMethod(type)
    /* if (type === 'COD') {
    cashOnDelivery()
    } else {
    createTempOrder(type)
    
    } */
  }
  const cashOnDelivery = (shipping_amount) => {
    const dataString = {
      cartSummary: contextValues.cartSummary,
      parsedAddressSession: contextValues.addressSession,
      parsedCartSession: contextValues.cartSessionData,
      parsedCouponSession: contextValues.couponSession,
      paymentMethod: 'COD',
      shippingCharge: shipping_amount,
    };
    setProccessLoader(true)
    ApiService.postData("makecodorder", dataString).then((res) => {
      if (res.status === "success") {
        localStorage.removeItem("CART_SESSION")
        localStorage.removeItem("ADDRESS_SESSION")
        localStorage.removeItem("COUPON_SESSION")
        navigate('/thankyou/' + res.order_number)
      } else {
        setProccessLoader(false)
      }
    });
  }

  const createTempOrder = (type, shipping_amount) => {
    const dataString = {
      cartSummary: contextValues.cartSummary,
      parsedAddressSession: contextValues.addressSession,
      parsedCartSession: contextValues.cartSessionData,
      parsedCouponSession: contextValues.couponSession,
      paymentMethod: type,
      shippingCharge: shipping_amount,
    };
    setProccessLoader(true)
    ApiService.postData("createtemporder", dataString).then((res) => {
      if (res.status === "success") {
        setProccessLoader(false)
        handlePayment(type, res)
      } else {
        setProccessLoader(false);
      }
    });
  };

  const handlePayment = async (type, res) => {
    const options = {
      key: "rzp_live_ndkiK2I7HW2jvQ", // Enter the Key ID generated from the Dashboard
      amount: res.row_temp_trans.total_amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "MtResin",
      description: "MtResin",
      image: contextValues.settingImageBaseUrl + contextValues.settingData.logo,
      order_id: res.row_temp_trans.temp_razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        RazorPayProcess(response, res.row_temp_trans.temp_trans_order_id, type);
      },
      prefill: {
        name: contextValues.addressSession.ua_name,
        email: contextValues.addressSession.ua_email,
        contact: contextValues.addressSession.ua_mobile,
      },
      notes: {
        address: "MtResin",
      },
      theme: {
        color: "#1b8057",
      },
      method: {
        netbanking: type == 'Net Banking' ? true : false,
        card: type == 'Debit/Credit Cards' ? true : false,
        upi: type == 'UPI' ? true : false,
        wallet: type == 'Wallet' ? true : false,
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };

  const RazorPayProcess = (response, orderId, type) => {
    const paymentData = {
      orderId: orderId,
      paymentId: response.razorpay_payment_id,
      cartSummary: contextValues.cartSummary,
      parsedAddressSession: contextValues.addressSession,
      parsedCartSession: contextValues.cartSessionData,
      parsedCouponSession: contextValues.couponSession,
      paymentMethod: type,
      shippingCharge: contextValues.shippingAmount,
    };
    setProccessLoader(true)
    ApiService.postData("paymentprocessing", paymentData).then((result) => {
      if (result.status === "success") {
        localStorage.removeItem("CART_SESSION")
        localStorage.removeItem("ADDRESS_SESSION")
        localStorage.removeItem("COUPON_SESSION")
        setProccessLoader(false);
        navigate('/thankyou/' + result.order_number)
      } else {
        setProccessLoader(false);
      }
    });
  };
  return (
    <>
      <BrowserView>
        <header className='header cartHeader'>
          <div className='container'>
            <div className='cartHeader-left'>
              <a href='/' className='logo'>
                <img src="/images/logo.png" alt="" width="100" height="70" />
              </a>
            </div>
            <div className='cartHeader-right'>
              <img src="/images/100SECURE.png" className="secure-img" />
            </div>
          </div>
        </header>
        <section className='section-gap-small'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-6'>
                <div className="ckboxbo mb-15">
                  <div className='ckboxbo-inner' data-bs-toggle="collapse" href="#collapseCheckOrder" role="button" aria-expanded="false" aria-controls="collapseCheckOrder">
                    <div className="ckboxbo-icon"><i className="ri-shopping-bag-3-line ri-xl mr-10"></i><span>Order Summary</span></div>
                    <div className="ckboxbo-itemscount">{contextValues.cartSessionData.length} Items <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
                  </div>
                  <div className="collapse" id="collapseCheckOrder">
                    <div className="collapseCheckOrderContent">
                      {contextValues.cartSessionData.map((value, index) => {
                        return (
                          <div className='ccocbox' key={index}>
                            <div className='ccocbox-product-media'>
                              <a href={"/product/" + value.product_slug}>
                                <img src={value.product_image} />
                              </a>
                            </div>
                            <div className='ccocbox-product-details'>
                              <a href={"/product/" + value.product_slug} className='ccocbox-product-name'>{value.product_name}</a>
                              <p className='mb-0 fw700 tx-12'>QTY : {value.quantity}</p>
                              {value.product_item_status ? <p className='mb-0 fw700 tx-12' style={{ 'color': 'red' }}>{value.product_item_status_message}</p> : null}
                            </div>
                            <div className='ccocbox-product-price'>{multiCurrency(value.product_selling_price)}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className='ckboxbo mb-15'>
                  <div className='ckboxbo-inner' data-bs-toggle="collapse" href="#collapseShippingAddress" role="button" aria-expanded="true" aria-controls="collapseShippingAddress">
                    <div className="ckboxbo-icon"><i className="ri-map-pin-line ri-xl mr-10"></i><span>Shipping &amp; Billing Address</span></div>
                    <div className="ckboxbo-itemscount"> <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
                  </div>
                  <div className="collapse show" id="collapseShippingAddress">
                    <div className="addressselbox">
                      {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                          {errorMessage}
                        </div>
                      )}
                      <button type="button" className="addnewaddress" onClick={(e) => checkoutAddressModal()}><i className="ri-add-circle-line ri-lg mr-5"></i>Add New Address</button>
                      {contextValues.userAddressList.length > 0 && contextValues.userAddressList.map((value, index) => (
                        <div className={`selectaddress ${selectAddresActive == value.ua_id ? 'active' : ''} mt-10`} key={index} onClick={(e) => selectAddress(value)}>
                          <div className='saddresslable'>{value.ua_address_type == "Other" ? value.ua_address_type_other : value.ua_address_type}</div>
                          <h6 className='mb-0 tx-14'>{value.ua_name}</h6>
                          <p className='mb-0 tx-13'>{value.ua_house_no}, {value.ua_city_name}, {value.ua_state_name}, {value.ua_pincode}</p>
                          <p className='tx-gray mb-0 tx-12'>+91 {value.ua_mobile} . {value.ua_email}</p>
                          <span className='selectad'></span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <h6 className='tx-gray mb-15'>Payment Methods</h6>
                <div className={`ckboxbo mb-15 ${paymentMethod === 'COD' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('COD')}>
                  <div className='ckboxbo-inner' >
                    <div className='ckboxbo-icon'>
                      <img className="mr-10" src="/images/delivery.png" style={{ width: '16%' }} />
                      <div>
                        <p className='tx-14 mb-0'>Cash on delivery</p>
                        <p className='tx-12 tx-gray mb-0'>Pay with cash</p>
                      </div>
                    </div>
                    <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                  </div>
                </div>
                <div className={`ckboxbo mb-15 ${paymentMethod === 'UPI' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('UPI')}>
                  <div className='ckboxbo-inner' >
                    <div className='ckboxbo-icon'>
                      <img className="mr-10" src="/images/phonepe.png" style={{ width: '13%' }} />
                      <div>
                        <p className='tx-14 mb-0'>Pay via UPI</p>
                        <p className='tx-12 tx-gray mb-0'>Use any registered UPI ID</p>
                      </div>
                    </div>
                    <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>                  
                  </div>
                </div>
                <div className={`ckboxbo mb-15 ${paymentMethod === 'Debit/Credit Cards' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Debit/Credit Cards')}>
                  <div className='ckboxbo-inner' >
                    <div className='ckboxbo-icon'>
                      <img className="mr-10" src="/images/creditcard.png" style={{ width: '11%' }} />
                      <div>
                        <p className='tx-14 mb-0'>Debit/Credit cards</p>
                        <p className='tx-12 tx-gray mb-0'>Visa, Mastercard, RuPay & more</p>
                      </div>
                    </div>
                    <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>                  
                  </div>
                </div>
                <div className={`ckboxbo mb-15 ${paymentMethod === 'Wallet' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Wallet')}>
                  <div className='ckboxbo-inner'>
                    <div className='ckboxbo-icon'>
                      <img className="mr-10" src="/images/paytm.png" style={{ width: '11%' }} />
                      <div>
                        <p className='tx-14 mb-0'>Wallets</p>
                        <p className='tx-12 tx-gray mb-0'>Paypal, Airtel, PayZapp & more</p>
                      </div>
                    </div>
                    <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>                  
                  </div>
                </div>
                <div className={`ckboxbo mb-15 ${paymentMethod === 'Net Banking' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Net Banking')}>
                  <div className='ckboxbo-inner' >
                    <div className='ckboxbo-icon'>
                      <img className="mr-10" src="/images/netbanking.png" style={{ width: '13%' }} />
                      <div>
                        <p className='tx-14 mb-0'>Netbanking</p>
                        <p className='tx-12 tx-gray mb-0'>Select from a list of banks</p>
                      </div>
                    </div>
                    <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>                  
                  </div>
                </div>
              </div>
              <div className='col-lg-4 border-left pl-20'>
                {contextValues.couponSession.promo_id > 0 ?
                  <div className='ckboxbo tx-primary mb-15'>
                    <div className='ckboxbo-inner' >
                      <div className='ckboxbo-icon'>
                        <i className="ri-coupon-2-line ri-xl mr-10"></i>
                        <div>
                          <p className='tx-14 mb-0'>{contextValues.couponSession.promo_code} applied successfully</p>
                          <p className='tx-12 tx-gray mb-0'> You save {contextValues.couponSession.discount_amount} on this order!</p></div>
                      </div>
                      <div className='ckboxbo-itemscount tx-14' style={{ color: 'red' }} onClick={(e) => removeCoupon()}><i className="ri-delete-bin-5-line ri-lg ml-5"></i></div>
                    </div>
                  </div>
                  : <div className='ckboxbo tx-primary mb-15'>
                    <div className='ckboxbo-inner' onClick={(e) => couponModal()}>
                      <div className='ckboxbo-icon'>
                        <i className="ri-price-tag-3-line ri-xl mr-10"></i>
                        <span>Coupons</span>
                      </div>
                      <div className='ckboxbo-itemscount tx-14'>{couponsCount}+ available <i className="ri-arrow-right-line ri-lg ml-5"></i></div>
                    </div>
                  </div>}
                <div className='ckboxbo mb-15'>
                  <div className='ckboxbo-inner'>
                    <div className="ckboxbo-icon"><span>Price Details</span></div>
                  </div>
                  <div className='pricebox'>
                    <ul>
                      <li>
                        <span>Items Total</span>
                        <span>{multiCurrency(contextValues.cartSummary.mrpTotal)}</span>
                      </li>
                      <li>
                        <span>Discount</span>
                        <span className='tx-green'>-{multiCurrency(contextValues.cartSummary.discount)}</span>
                      </li>
                      <li>
                        <span>Coupon Discount</span>
                        <span className='tx-green'>-{multiCurrency(contextValues.couponSession.discount_amount)}</span>
                      </li>
                      <li>
                        <span>Shipping</span>
                        <span>{multiCurrency(contextValues.shippingAmount)}</span>
                      </li>
                      <li className="fw700 border-top border-bottom mt-3 pt-4 pb-4 totalamount">
                        <span>Total Amount</span>
                        <span>{multiCurrency(Number(contextValues.cartSummary.total_amount) - Number(contextValues.couponSession.discount_amount) + Number(contextValues.shippingAmount))}</span>
                      </li>
                    </ul>
                    <p className="text-center mt-20">We Accepted all Major Cards</p>
                    <div className="cardlist">
                      <i className="ri-paypal-line ri-2x"></i>
                      <i className="ri-mastercard-line ri-2x"></i>
                      <i className="ri-visa-fill ri-2x"></i>
                    </div>
                  </div>
                </div>
                <button type='button' className='btn btn-primary btn-full btn-md ckbutton mb-20' onClick={(e) => proccessToPayment()}>{proccessLoader ? (<img src="/images/loder01.gif" width="60px" height="11px" />) : ("Proceed to pay")}</button>
              </div>
            </div>
          </div>
        </section>
      </BrowserView>
      <MobileView>
        <header className='header cartHeader cartHeaderSecond'>
          <div className='container'>
            <div className='cartHeader-left'>
              <a className='mobile-header-title' href='/'>
                <img src='/images/back.png' className='mr-15 wd-24'></img>
                Checkout
              </a>
            </div>
            <div className='cartHeader-right'>
              <img src="/images/100SECURE.png" className="secure-img" />
            </div>
          </div>
        </header>
        <div id='appCapsule'>
          <div className='p-15'>
            <div className='ckboxbo mb-15'>
              <div className={`ckboxbo-inner ${addressToggleCollaps ? '' : 'ckboxbo-inner-address'}`} data-bs-toggle="collapse" href="#collapseShippingAddress" role="button" aria-expanded="false" aria-controls="collapseShippingAddress" onClick={(e) => toggleAddressCollapsModal()}>
                <div className='d-flex align-items-center justify-content-between' style={{ width: '100%' }}>
                  <div className="ckboxbo-icon"><i className="ri-map-pin-line ri-xl mr-10"></i><span>Delivery Address</span></div>
                  <div className="ckboxbo-itemscount"> <i className="ri-edit-box-line ri-lg ml-5"></i></div>
                </div>
                {!addressToggleCollaps && <div className='ccoselectaddress'>{contextValues.addressSession.ua_house_no}, {contextValues.addressSession.ua_city_name}, {contextValues.addressSession.ua_state_name}, {contextValues.addressSession.ua_pincode}</div>}
              </div>
              <div className={`collapse ${addressToggleCollaps ? 'show' : ''}`} id="collapseShippingAddress">
                <div className="addressselbox">
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <button type="button" className="addnewaddress" onClick={(e) => checkoutAddressModal()}><i className="ri-add-circle-line ri-lg mr-5"></i>Add New Address</button>
                  {contextValues.userAddressList.length > 0 && contextValues.userAddressList.map((value, index) => (
                    <div className={`selectaddress ${selectAddresActive == value.ua_id ? 'active' : ''} mt-10`} key={index} onClick={(e) => selectAddress(value)}>
                      <div className='saddresslable'>{value.ua_address_type == "Other" ? value.ua_address_type_other : value.ua_address_type}</div>
                      <h6 className='mb-0 tx-14'>{value.ua_name}</h6>
                      <p className='mb-0 tx-13'>{value.ua_house_no}, {value.ua_city_name}, {value.ua_state_name}, {value.ua_pincode}</p>
                      <p className='tx-gray mb-0 tx-12'>+91 {value.ua_mobile} . {value.ua_email}</p>
                      <span className='selectad'></span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="ckboxbo mb-15">
              <div className='ckboxbo-inner' data-bs-toggle="collapse" href="#collapseCheckOrder" role="button" aria-expanded="false" aria-controls="collapseCheckOrder">
                <div className="ckboxbo-icon"><i className="ri-shopping-bag-3-line ri-xl mr-10"></i><span>Order Summary</span></div>
                <div className="ckboxbo-itemscount">{contextValues.cartSessionData.length} Items <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
              </div>
              <div className="collapse" id="collapseCheckOrder">
                <div className="collapseCheckOrderContent">
                  {contextValues.cartSessionData.map((value, index) => {
                    return (
                      <div className='ccocbox' key={index}>
                        <div className='ccocbox-product-media'>
                          <a href={"/product/" + value.product_slug}>
                            <img src={value.product_image} />
                          </a>
                        </div>
                        <div className='ccocbox-product-details'>
                          <a href={"/product/" + value.product_slug} className='ccocbox-product-name'>{value.product_name}</a>
                          <p className='mb-0 fw700 tx-12'>QTY : {value.quantity}</p>
                          {value.product_item_status ? <p className='mb-0 fw700 tx-12' style={{ 'color': 'red' }}>{value.product_item_status_message}</p> : null}
                        </div>
                        <div className='ccocbox-product-price'>{multiCurrency(value.product_selling_price)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {contextValues.couponSession.promo_id > 0 ?
              <div className='ckboxbo tx-primary mb-15'>
                <div className='ckboxbo-inner' >
                  <div className='ckboxbo-icon'>
                    <i className="ri-coupon-2-line ri-xl mr-10"></i>
                    <div>
                      <p className='tx-14 mb-0'>{contextValues.couponSession.promo_code} applied successfully</p>
                      <p className='tx-12 tx-gray mb-0'> You save {contextValues.couponSession.discount_amount} on this order!</p></div>
                  </div>
                  <div className='ckboxbo-itemscount tx-14' style={{ color: 'red' }} onClick={(e) => removeCoupon()}><i className="ri-delete-bin-5-line ri-lg ml-5"></i></div>
                </div>
              </div>
              : <div className='ckboxbo tx-primary mb-15'>
                <div className='ckboxbo-inner' onClick={(e) => couponModal()}>
                  <div className='ckboxbo-icon'>
                    <i className="ri-price-tag-3-line ri-xl mr-10"></i>
                    <span>Coupons</span>
                  </div>
                  <div className='ckboxbo-itemscount tx-14'>{couponsCount}+ available <i className="ri-arrow-right-line ri-lg ml-5"></i></div>
                </div>
              </div>}
            <div className='ckboxbo mb-15'>
              <div className='ckboxbo-inner'  data-bs-toggle="collapse" href="#collapsePrice" role="button" aria-expanded="false" aria-controls="collapsePrice">
              <div className="ckboxbo-icon"><i className="ri-money-rupee-circle-line ri-xl mr-10"></i><span>Price Details</span></div>
                <div className="ckboxbo-itemscount"><span className='fw700'>{multiCurrency(Number(contextValues.cartSummary.total_amount) - Number(contextValues.couponSession.discount_amount) + Number(contextValues.shippingAmount))}</span> <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
              </div>
              <div className="collapse" id="collapsePrice">
              <div className='pricebox'>
                <ul>
                  <li>
                    <span>Items Total</span>
                    <span>{multiCurrency(contextValues.cartSummary.mrpTotal)}</span>
                  </li>
                  <li>
                    <span>Discount</span>
                    <span className='tx-green'>-{multiCurrency(contextValues.cartSummary.discount)}</span>
                  </li>
                  <li>
                    <span>Coupon Discount</span>
                    <span className='tx-green'>-{multiCurrency(contextValues.couponSession.discount_amount)}</span>
                  </li>
                  <li>
                    <span>Shipping</span>
                    <span>{multiCurrency(contextValues.shippingAmount)}</span>
                  </li>
                  <li className="fw700 border-top mt-3 pt-4 pb-4 totalamount">
                    <span>Total Amount</span>
                    <span>{multiCurrency(Number(contextValues.cartSummary.total_amount) - Number(contextValues.couponSession.discount_amount) + Number(contextValues.shippingAmount))}</span>
                  </li>
                </ul>
                
              </div>
              </div>
            </div>
            <div className={`ckboxbo mb-15 ${paymentMethod === 'COD' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('COD')}>
                  <div className='ckboxbo-inner' >
                    <div className='ckboxbo-icon'>
                      <img className="mr-10" src="/images/delivery.png" style={{ width: '16%' }} />
                      <div>
                        <p className='tx-14 mb-0'>Cash on delivery</p>
                        <p className='tx-12 tx-gray mb-0'>Pay with cash</p>
                      </div>
                    </div>
                    <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                  </div>
                </div>
                <div className={`ckboxbo mb-15 ${paymentMethod === 'UPI' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('UPI')}>
                  <div className='ckboxbo-inner' >
                    <div className='ckboxbo-icon'>
                      <img className="mr-10" src="/images/phonepe.png" style={{ width: '13%' }} />
                      <div>
                        <p className='tx-14 mb-0'>Pay via UPI</p>
                        <p className='tx-12 tx-gray mb-0'>Use any registered UPI ID</p>
                      </div>
                    </div>
                    <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                  </div>
                </div>
                <div className={`ckboxbo mb-15 ${paymentMethod === 'Debit/Credit Cards' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Debit/Credit Cards')}>
                  <div className='ckboxbo-inner' >
                    <div className='ckboxbo-icon'>
                      <img className="mr-10" src="/images/creditcard.png" style={{ width: '11%' }} />
                      <div>
                        <p className='tx-14 mb-0'>Debit/Credit cards</p>
                        <p className='tx-12 tx-gray mb-0'>Visa, Mastercard, RuPay & more</p>
                      </div>
                    </div>
                    <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                  </div>
                </div>
                <div className={`ckboxbo mb-15 ${paymentMethod === 'Wallet' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Wallet')}>
                  <div className='ckboxbo-inner'>
                    <div className='ckboxbo-icon'>
                      <img className="mr-10" src="/images/paytm.png" style={{ width: '11%' }} />
                      <div>
                        <p className='tx-14 mb-0'>Wallets</p>
                        <p className='tx-12 tx-gray mb-0'>Paypal, Airtel, PayZapp & more</p>
                      </div>
                    </div>
                    <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                  </div>
                </div>
                <div className={`ckboxbo mb-15 ${paymentMethod === 'Net Banking' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Net Banking')}>
                  <div className='ckboxbo-inner' >
                    <div className='ckboxbo-icon'>
                      <img className="mr-10" src="/images/netbanking.png" style={{ width: '13%' }} />
                      <div>
                        <p className='tx-14 mb-0'>Netbanking</p>
                        <p className='tx-12 tx-gray mb-0'>Select from a list of banks</p>
                      </div>
                    </div>
                    <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                  </div>
                </div>
          </div>
        </div>
        <div className='checkoutFooter'>
          <div className='p-15'>
            <button type='button' className='btn btn-primary btn-full btn-md ckbutton' onClick={(e) => proccessToPayment()}>{proccessLoader ? (<img src="/images/loder01.gif" width="60px" height="11px" />) : ("Proceed to pay")}</button>
          </div>
        </div>
       
      </MobileView>
      <CouponModal />
      <PaymentModal />
      <CheckoutAddressModal />
    </>

  )
}

export default Checkout