import React, { useEffect, useRef, useState, useContext } from 'react'
import { ApiService } from '../Services/apiservices'
import { validEmail } from '../../Components/Elements/Regex';
import DataContext from '../Elements/eventContext';
import { BrowserView, MobileView } from 'react-device-detect';
import { useLocation } from "react-router-dom";
function Footer() {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const contextValues = useContext(DataContext);
    const { settingData, setSettingData } = useContext(DataContext);
    const didMountRef = useRef(true)
    //const [settingData, setSettingData] = useState({})
    const [footer1, setFooter1] = useState("");
    const [footer2, setFooter2] = useState("");
    const [footer3, setFooter3] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [setSession, SetSession] = useState('')
    const [successMessage, setSuccessMessage] = useState("");
    const [accountStatus, SetAccountStatus] = useState(false)
    const handleShow = () => setShow(true);
    const handleChildData = (status) => {
        setShow(status)
    };
    const [newsletterDetails, setNewsletterDetails] = useState({
        newsletter_email: "",
    });

    const loginModal = () => {
        contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
    }
    useEffect(() => {
        if (didMountRef.current) {
            // getSettingData()
            getFooterData()
            SetSession(localStorage.getItem('USER_TOKEN'))
            const currentPath = location.pathname;
            const isAccountOverview = currentPath.includes("/account/account-overview");
            const isAddressPage = currentPath.includes("/account/address");
            const isWishlistPage = currentPath.includes("/account/wishlist");
            const ishelpandsupportPage = currentPath.includes("/account/help-and-support");
            const isaboutusPage = currentPath.includes("/account/about-us");

            if (isAccountOverview || isWishlistPage || isAddressPage || ishelpandsupportPage || isaboutusPage) {
                SetAccountStatus(true)
            } else {
                SetAccountStatus(false)
            }
        }
        didMountRef.current = false;
    }, []);

    /* const getSettingData = () => {
    ApiService.fetchData("settingsdata").then((res) => {
    if (res.status == "success") {
    setSettingData(res.sitesettings)
    }
    })
    } */

    const getFooterData = () => {
        ApiService.fetchData("footer").then((res) => {
            if (res.status == "success") {
                setFooter1(res.footerIdData?.footer_desc1);
                setFooter2(res.footerIdData?.footer_desc2);
                setFooter3(res.footerIdData?.footer_desc3);

            }
        })
    }
    const onTodoChange = (e) => {
        const { name, value } = e.target;
        setNewsletterDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const resetNewsletterForm = () => {
        setNewsletterDetails({ newsletter_email: "" });
    };
    const newsletterProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("newsletterRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter === 0) {
            setErrorMessage("");
            const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!validEmailRegex.test(newsletterDetails.newsletter_email)) {
                setErrorMessage("Please enter valid Email Id");
                return false;
            }
            ApiService.postData('newsletter-process', newsletterDetails).then((res) => {
                if (res.status === "success") {
                    setSuccessMessage(res.message)
                    resetNewsletterForm();
                    setTimeout(() => {
                        setSuccessMessage('')
                    }, 2000);
                } else {
                    setErrorMessage(res.message)
                    setTimeout(() => {
                        setErrorMessage('')
                    }, 2000);
                }
            });
        }
    };

    return (
        <>
            <BrowserView>
                <div className="footerIocn">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/images/f05.png" alt="Shipping Icon" /></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Free Shipping</h6>
                                        <p className="tx-12 mb-0">Order above Rs.500</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/images/f04.png" alt="Save Icon" /></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Save Money</h6>
                                        <p className="tx-12 mb-0">All products at lowest price</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/images/f01.png" alt="Deal Icon" /></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Best Deal Offer</h6>
                                        <p className="tx-12 mb-0">Grab Your Gear and Go</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/images/f02.png" alt="Quality Icon" /></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Quality assurance </h6>
                                        <p className="tx-12 mb-0">Grab Your Gear and Go</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer>
                    <div className='footerTop'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-7'>
                                    <h3>Join our newsletter</h3>
                                    <p className='mb-0'>Register now to get latest updates on promotions & coupons.Don’t worry, we not spam!</p>
                                </div>
                                <div className="col-lg-5">
                                    {errorMessage && (<div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>)}
                                    {successMessage && (<div className="alert alert-success" role="alert">
                                        {successMessage}
                                    </div>)}
                                    <div className="subscribe-form">
                                        <input type="email" placeholder="Enter your email here.." name="newsletter_email"
                                            value={newsletterDetails.newsletter_email}
                                            onChange={(e) => onTodoChange(e)}
                                        />
                                        <button type='button' className="subscribe-button" onClick={newsletterProcess}
                                        >Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footerMiddle'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <ul className='footerContact'>
                                        <li>
                                            <div className='fcbix' dangerouslySetInnerHTML={{ __html: footer1 }}>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='fcbix'>
                                                <div className='fcbix-icon'>
                                                    <i className="ri-phone-line"></i>
                                                </div>
                                                <div className='fcbix-text'>
                                                    <p className='mb-1 tx-gray tx-12'>24/7 Support</p>
                                                    <a href={`tel:${settingData.admin_support_mobile}`}>
                                                    <h6>
                                                        {settingData?.admin_support_mobile}
                                                    </h6></a>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='fcbix'>
                                                <div className='fcbix-icon'>
                                                    <i className="ri-mail-send-line"></i>
                                                </div>
                                                <div className='fcbix-text'>
                                                    <p className='mb-1 tx-gray tx-12'>Need help with your order?</p>
                                                    <a
                                                        href={"mailto:" + settingData?.admin_support_email}
                                                    >
                                                        <h6>{settingData?.admin_support_email}</h6>
                                                    </a>
                                                </div>

                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div className='col-lg-4' dangerouslySetInnerHTML={{ __html: footer2 }}>
                                </div>
                                <div className='col-lg-2' dangerouslySetInnerHTML={{ __html: footer3 }}>
                                </div>
                                <div className='col-lg-3'>


                                    <h2 className='footer-title mb-20'>Follow Us On</h2>

                                    <ul className='social-footer mb-30'>
                                        {settingData.facebook_url != null && (
                                            <li><a href={settingData.facebook_url} target='_blank'><i className="ri-facebook-fill"></i></a></li>
                                        )}
                                        {settingData.instagram_url != null && (
                                            <li><a href={settingData.instagram_url} target='_blank'><i className="ri-instagram-fill"></i></a></li>
                                        )}
                                        {settingData.youtube_url != null && (
                                            <li><a href={settingData.youtube_url} target='_blank'><i className="ri-youtube-fill"></i></a></li>
                                        )}

                                        {settingData.twitter_url != null && (
                                            <li><a href={settingData.twitter_url} target='_blank'><i className="ri-twitter-fill"></i></a></li>
                                        )}
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footerBottom'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>Copyright 2024 © All Right Reserved MT Resin India</div>
                                <div className='col-lg-6'>
                                    <div className="d-flex justify-content-end"><img src="/images/payments.png" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </BrowserView>

            <MobileView>
                <div className="footerIocn">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/images/f05.png" alt="Shipping Icon" /></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Free Shipping</h6>
                                        <p className="tx-12 mb-0">Order above Rs.500</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/images/f04.png" alt="Save Icon" /></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Save Money</h6>
                                        <p className="tx-12 mb-0">All products at lowest price</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/images/f01.png" alt="Deal Icon" /></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Best Deal Offer</h6>
                                        <p className="tx-12 mb-0">Grab Your Gear and Go</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/images/f02.png" alt="Quality Icon" /></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Quality assurance </h6>
                                        <p className="tx-12 mb-0">Grab Your Gear and Go</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footerbottomMobile'>
                    <div className='footercolspace' data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                        MT Resin Arts India
                        <i class="ri-arrow-down-s-line ri-xl"></i>
                    </div>

                    <div class="collapse" id="collapseExample">
                        <footer>
                            <div className='footerTop'>
                                <div className='container'>
                                    <div className='row align-items-center'>
                                        <div className='col-lg-7'>
                                            <h3>Join our newsletter</h3>
                                            <p className='mb-0'>Register now to get latest updates on promotions & coupons.Don’t worry, we not spam!</p>
                                        </div>
                                        <div className="col-lg-5">
                                            {errorMessage && (<div className="alert alert-danger" role="alert">
                                                {errorMessage}
                                            </div>)}
                                            {successMessage && (<div className="alert alert-success" role="alert">
                                                {successMessage}
                                            </div>)}
                                            <div className="subscribe-form">
                                                <input type="email" placeholder="Enter your email here.." name="newsletter_email"
                                                    value={newsletterDetails.newsletter_email}
                                                    onChange={(e) => onTodoChange(e)}
                                                />
                                                <button type='button' className="subscribe-button" onClick={newsletterProcess}
                                                >Subscribe</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='footerMiddle'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                            <div dangerouslySetInnerHTML={{ __html: footer1 }}>
                                            </div>

                                            <ul className='footerContact'>
                                                <li>
                                                    <div className='fcbix'>
                                                        <div className='fcbix-icon'>
                                                            <i className="ri-phone-line"></i>
                                                        </div>
                                                        <div className='fcbix-text'>
                                                            <p className='mb-1 tx-gray tx-12'>24/7 Support</p>
                                                            <a href={"tel:" + settingData?.admin_support_mobile}><h6>
                                                                {settingData?.admin_support_mobile}
                                                            </h6></a>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='fcbix'>
                                                        <div className='fcbix-icon'>
                                                            <i className="ri-mail-send-line"></i>
                                                        </div>
                                                        <div className='fcbix-text'>
                                                            <p className='mb-1 tx-gray tx-12'>Need help with your order?</p>
                                                            <a
                                                                href={"mailto:" + settingData?.admin_support_email}
                                                            >
                                                                <h6>{settingData?.admin_support_email}</h6>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-4' dangerouslySetInnerHTML={{ __html: footer2 }}>
                                        </div>
                                        <div className='col-lg-2' dangerouslySetInnerHTML={{ __html: footer3 }}>
                                        </div>
                                        <div className='col-lg-3'>

                                            <h2 className='footer-title mb-20'>Follow Us On</h2>

                                            <ul className='social-footer'>
                                                {settingData.facebook_url != null && (
                                                    <li><a href={settingData.facebook_url} target='_blank'><i className="ri-facebook-fill"></i></a></li>
                                                )}
                                                {settingData.instagram_url != null && (
                                                    <li><a href={settingData.instagram_url} target='_blank'><i className="ri-instagram-fill"></i></a></li>
                                                )}
                                                {settingData.youtube_url != null && (
                                                    <li><a href={settingData.youtube_url} target='_blank'><i className="ri-youtube-fill"></i></a></li>
                                                )}

                                                {settingData.twitter_url != null && (
                                                    <li><a href={settingData.twitter_url} target='_blank'><i className="ri-twitter-fill"></i></a></li>
                                                )}
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='footerBottom'>
                                <div className='container'>
                                    <div className='row align-items-center'>
                                        <div className='col-lg-6'>Copyright 2024 © All Right Reserved MT Resin India</div>
                                        <div className='col-lg-6'>
                                            <div className="d-flex justify-content-end"><img src="/images/payments.png" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>


                <div className="appBottomMenu">
                    <a href="/" className={location.pathname === "/" ? "item active" : "item"}>
                        <div className="col">
                            <i class="ri-home-4-line"></i><span>Home</span>
                        </div>
                    </a>
                    <a href="/category" className={location.pathname.includes("/category") ? "item active" : "item"}>
                        <div className="col">
                            <i class="ri-stack-line"></i>
                            <span>Categories</span>
                        </div>
                    </a>
                    {setSession ?
                        <a href="/account/orders" className={location.pathname.includes("/account/orders") ? "item active" : "item"}>
                            <div className="col">
                                <i class="ri-truck-line"></i>
                                <span>Orders</span>
                            </div>
                        </a> :
                        <a className="item" onClick={(e) => { e.preventDefault(); loginModal() }}>
                            <div className="col">
                                <i class="ri-truck-line"></i>
                                <span>Orders</span>
                            </div>
                        </a>}
                    {setSession ? (
                        <a href="/account/account-overview" className={accountStatus ? "item active" : "item"}>
                            <div className="col">
                                <i class="ri-user-line"></i>
                                <span>Account</span>
                            </div>
                        </a>
                    )
                        : (
                            <a className="item" onClick={(e) => { e.preventDefault(); loginModal() }}>
                                <div className="col">
                                    <i class="ri-user-line"></i>
                                    <span>Account</span>
                                </div>
                            </a>
                        )
                    }
                </div>
            </MobileView>
        </>
    )
}

export default Footer