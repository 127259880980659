const API_URL = 'https://codexotech.in/projects/mt-resin/csadmin/api/web-api/'
//const API_URL = 'https://dryfu.com/csadmin/api/web-api/'

const BASE_URL =   ""    
const FRONT_URL =   ""    
const DEFAULT_IMAGE = "/images/defaultimage.png";
const GOOGLE_URL = "https://maps.googleapis.com/maps/api/"
const GOOGLE_MAP_KEY = "AIzaSyCJrkzyUKM6gAm585P3CNbim9rwMJPE6zU";

export default { API_URL,BASE_URL,FRONT_URL,DEFAULT_IMAGE,GOOGLE_URL,GOOGLE_MAP_KEY };

