import React, { useEffect, useState, useRef } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { useNavigate } from "react-router-dom";
function Search() {
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [recentsearchdata, setrecentsearchdata] = useState([]);
  const goToPage = (route) => {
    navigate(route);
  };
  const navigateToPreviousPage = () => {
    navigate(-1); // Navigates back one step in the history stack
  };
  useEffect(() => {
    getrecentsearch()
  }, [])

  const fetchSearchResults = (searchquery) => {
    if (searchquery && searchquery.length > 2) {
      const dataString = {
        query: searchquery,
      }
      ApiService.postData(
        "get-search-data", dataString
      ).then((res) => {
        if (res.status === "success") {
          setSearchResults(res.data);
        } else {
        }
      });
    }
    else {
      setSearchResults([])
    }

  }

  const recentsearchlist = (item) => {
    if (item) {
      try {
        let recentsearch = JSON.parse(localStorage.getItem('recentsearch')) || []; // Parse stored data to JSON
        const isProductAlreadyExists = recentsearch.some(product => product.name === item.name);
        if (!isProductAlreadyExists) {
          recentsearch.push(item);
        } else {
          recentsearch = recentsearch.filter(product => product.name !== item.name);
          recentsearch.push(item);
        }
        localStorage.setItem('recentsearch', JSON.stringify(recentsearch)); // Stringify recentsearch before storing
        setTimeout(() => {
          if (item.redirect) {
            goToPage(item.redirect);
            setSearchResults([])
          }
        }, 500);
      } catch (error) {
      }
    }
  }
  const getrecentsearch = () => {
    try {
      const recentsearchResult = JSON.parse(localStorage.getItem('recentsearch'));
      if (recentsearchResult !== null) {
        setrecentsearchdata(recentsearchResult);
      } else {
        setrecentsearchdata([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  const clearsearch = () => {
    localStorage.removeItem('recentsearch')
    getrecentsearch()
  }

  return (
    <>


      <div class="locationHeaderSearch">
        <button type="button" class="searchbackbutton" onClick={()=>{navigateToPreviousPage()}}><i class="ri-arrow-left-line ri-xl"></i></button>
        <input type="text" placeholder="Search..." onChange={(e) => { fetchSearchResults(e.target.value) }} />
      </div>
      <div class="searchwapper">
        <div class="p-15 pt-0">
          {recentsearchdata.length > 0 ? <>
            <div class="d-flex align-items-center mb-10">
              <h6 class="mb-0 mt-0">Recent Searches</h6>
              <button type="button" class="clearbutton" onClick={() => { clearsearch() }} >Clear</button>
            </div>
            <div class="recentlist">
              <ul>
                {recentsearchdata && recentsearchdata?.map((item, index) => {
                  return (<>
                    <li onClick={() => { goToPage(item.redirect) }} key={index}>{item?.name}<i class="ri-arrow-right-up-line"></i></li>

                  </>)
                })}

              </ul>
            </div>


          </> : ""}

          {searchResults.length > 0 ? <>
            <div class="searchlist">
              <ul>
                {searchResults.map((value, index) => {
                  return (<>
                    <li key={index} onClick={() => { recentsearchlist(value) }}>
                      <div class="searchlistbox">
                        <div class="searchlistboxIoc"><img src={value.img}></img></div>
                        <div style={{ flex: '1' }}>{value.name}</div>
                        <i class="ri-arrow-right-up-line ml-auto ri-xl"></i>
                      </div>
                    </li>
                  </>)
                })}

              </ul>
            </div>
          </> : ''}

        </div>
      </div>
    </>
  )
}
export default Search
