import React, { createContext, useState } from 'react';
const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);
  const [quickViewData, setQuickViewData] = useState({});
  const [quickModalStatus, setQuickModalStatus] = useState(false);
  const [settingData, setSettingData] = useState({});
  const [settingImageBaseUrl, setSettingImageBaseUrl] = useState('');
  const [spinnerCubLoader, setSpinnerCubLoader] = useState(0);
  const [cartSessionData, setCartSessionData] = useState([]);
  const [toggleCheckoutModal, setToggleCheckoutModal] = useState(false);
  const [toggleVariationModal, setToggleVariationModal] = useState(false);
  const [toggleCartModal, setToggleCartModal] = useState(false);
  const [toggleCouponModal, setToggleCouponModal] = useState(false);
  const [togglePaymentModal, setTogglePaymentModal] = useState(false);
  const [toggleLoginModal, setToggleLoginModal] = useState(false);
  const [toggleRegisterModal, setToggleRegisterModal] = useState(false);
  const [toggleReviewModal, setToggleReviewModal] = useState(false);
  const [toggleQuickViewModal, setToggleQuickViewModal] = useState(false);
  const [toggleReviewListModal, setToggleReviewListModal] = useState(false);
  const [toggleCheckoutAddressModal, setToggleCheckoutAddressModal] = useState(false);
  const [toggleMenuModal, setToggleMenuModal] = useState(false);
  const [cartSummary, setCartSummary] = useState({});
  const [couponSession, setCouponSession] = useState({});
  const [addressSession, setAddressSession] = useState({});
  const [userToken, setUserToken] = useState('');
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [deliveryLocationData, setDeliveryLocationData] = useState({});
  const [productData, setProductData] = useState({});
  const [userAddressList, setUserAddressList] = useState([]);
  const [shippingAmount, setShippingAmount] = useState(0);
  const [newAddress, setNewAddress] = useState({ ua_id: 0, ua_house_no: '', ua_area: '', ua_address_type: 'Home', ua_name: '', ua_email: '', ua_address_type_other: '' });
  const [rowProductsData, setProductsData] = useState({});
  const [rowUserData, setRowUserData] = useState({});
  return (
    <DataContext.Provider value={
      {
        cartCount, setCartCount,
        quickViewData, setQuickViewData,
        quickModalStatus, setQuickModalStatus,
        settingData, setSettingData,
        settingImageBaseUrl, setSettingImageBaseUrl,
        spinnerCubLoader, setSpinnerCubLoader,
        cartSessionData, setCartSessionData,
        toggleCheckoutModal, setToggleCheckoutModal,
        toggleCartModal, setToggleCartModal,
        toggleCouponModal, setToggleCouponModal,
        togglePaymentModal, setTogglePaymentModal,
        toggleMenuModal, setToggleMenuModal,
        cartSummary, setCartSummary,
        couponSession, setCouponSession,
        addressSession, setAddressSession,
        userToken, setUserToken,
        toggleCheckoutAddressModal, setToggleCheckoutAddressModal,
        showLocationModal, setShowLocationModal,
        deliveryLocationData, setDeliveryLocationData,
        userAddressList, setUserAddressList,
        shippingAmount, setShippingAmount,
        toggleVariationModal, setToggleVariationModal,
        productData, setProductData,
        toggleLoginModal, setToggleLoginModal,
        toggleRegisterModal, setToggleRegisterModal,

        toggleReviewModal, setToggleReviewModal,
        toggleReviewListModal, setToggleReviewListModal,
        newAddress, setNewAddress,
        rowProductsData, setProductsData,
        rowUserData, setRowUserData,
        toggleQuickViewModal, setToggleQuickViewModal
      }
    }>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;