import React, { useEffect, useRef, useState } from 'react'
import { MobileView } from 'react-device-detect';
import MobileHeader from '../../Components/Elements/mobile_header';
import { ApiService } from "../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
import constant from '../../Components/Services/constant';
import { useNavigate } from "react-router-dom";
function Category() {
  const navigate = useNavigate()
  const didMountRef = useRef(true);
  const [categorydata, setcategorydata] = useState([])
  const [catimgpath, setcatimgpath] = useState('')
  const [loading, setloading] = useState(true)
  const [openCategoryIndices, setOpenCategoryIndices] = useState([]);

  const toggleCategory = (item, index) => {

    if (item.children.length > 0) {
      setOpenCategoryIndices(prevIndices => {
        if (prevIndices.includes(index)) {
          return prevIndices.filter(i => i !== index);
        } else {
          return [...prevIndices, index];
        }
      });
    }
    else {
      navigate(`/collection/category/${item.cat_slug}`)
    }

  };
  const defaultImg = constant.DEFAULT_IMAGE
  useEffect(() => {
    if (didMountRef.current) {
      getcategorydata()
    }
    didMountRef.current = false;
  }, []);

  const getcategorydata = () => {
    ApiService.fetchData(
      "all-categories"
    ).then((res) => {
      if (res.status === "success") {
        setcategorydata(res.allCategories);
        setcatimgpath(res.category_image_path)
        setloading(false)
      } else {
        setloading(false)
      }
    }).catch((error) => {
      console.log(error)
      setloading(false)
    });
  }
  return (
    <>
      <MobileView>
        <MobileHeader
          Route="" PageName="Categories" cartCount={0}
        />
        <div id="appCapsule">
          <div class="catlistaccordation">
            {loading === true ? (
              <>
                {[...Array(8)].map((_, index) => (
                  <> 
                    <div class="catlistaccordHeader" key={index}>
                      <div className='catlistaccordHeadermc'>
                        <Skeleton width={50} height={50}/>
                        <div className='catlistaccordHeadermc-content'>
                          <h6 class="mb-0 mt-0"><Skeleton variant="text" width={100} height={10}/></h6>
                        </div>
                      </div> 
                    </div>
                  </>
                ))}
              </>
            ) : <>
              {categorydata && categorydata?.map((item, index) => {
                return (<>
                  <div class="catlistaccordHeader" key={index} onClick={() => toggleCategory(item, index)}>
                    <div className='catlistaccordHeadermc'>
                      <div className='catlistaccordHeadermc-media'>
                        <img src={item.cat_image ? catimgpath + item.cat_image : defaultImg}></img>
                      </div>
                      <div className='catlistaccordHeadermc-content'>
                        <h6 class="mb-0 mt-0">{item.cat_name}</h6>
                      </div>
                    </div>
                    {item.children.length > 0 ? <>
                      <i class="ri-arrow-down-s-line ri-xl" ></i>
                    </> : <>
                      <i class="ri-arrow-right-s-line ri-xl" ></i>
                    </>}
                  </div>
                  {openCategoryIndices.includes(index) && item?.children?.length > 0 ?
                    <div class="catlistaccordContent"  >
                      <ul >
                        {item.children.map((children, index) => {
                          return (<>
                            <li key={index} onClick={() => { navigate(`/collection/category/${children.cat_slug}`) }}><p class="mb-0 mt-0 tx-14">{children.cat_name}</p> <i class="ri-arrow-right-s-line ri-xl"></i></li>
                          </>)
                        })}
                      </ul>
                    </div>
                    : " "}
                </>)
              })}
            </>}
          </div>
        </div>
      </MobileView>
    </>
  )
}

export default Category