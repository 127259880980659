import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'
import { validEmail } from '../../Components/Elements/Regex';

function ContactUs() {
    const didMountRef = useRef(true);
    const [pageContent, setPageContent] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [settingData, setSettingData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [contactDetails, setContactDetails] = useState({
        contact_name: "",
        contact_email: "",
        contact_mobile: "",
        contact_message: "",
    });
    useEffect(() => {
        if (didMountRef.current) {
            getPageData()
            getSettingsData()
        }
        didMountRef.current = false;
    })

    const getSettingsData = () => {
        ApiService.fetchData("settingsdata").then((res) => {
            if (res.status == "success") {
                setSettingData(res.sitesettings);
            }
        });
    };
    const getPageData = () => {
        const pageData = {
            slug: "contact-us",
        }
        ApiService.postData("pagecontent", pageData).then((res) => {
            if (res.status == "success") {
                setPageContent(res.data)
            }
        })
    }
    const onTodoChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const resetContactForm = () => {
        setContactDetails({
            contact_name: "",
            contact_email: "",
            contact_subject: "",
            contact_message: "",
        });
    };
    const contactusProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("required");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {
                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }
        if (counter === 0) {
            setErrorMessage("");
            if (!validEmail.test(contactDetails.contact_email)) {
                setErrorMessage("Please enter valid Email Id");
                return false;
            }
            ApiService.postData("contact-us-process", contactDetails).then((res) => {
                if (res.status === "success") {
                    setSuccessMessage(res.message);
                    resetContactForm();
                    setTimeout(() => {
                        setSuccessMessage("");
                    }, 2000);
                } else {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        setErrorMessage("");
                    }, 2000);
                }
            });
        }
    };

    return (
        <>
            <Header />
            <div className="subheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <h1>{pageContent.page_name}</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{pageContent.page_name}</li>
                                    </ol>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
            <section class="section-gap-medium">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="section-title">
                                <h2>Get In Touch With Us</h2>
                                <p>If you have any questions or enquiries please feel free to contact us alternatively you can complete our online enquiry form located below and we will get back to you as soon as possible.</p>
                            </div>
                            <div class="row g-3">
                                <div class="col-lg-12">
                                    <div class="form-group"><label>Your Name</label>
                                        <input
                                            type="text"
                                            name="contact_name"
                                            className="form-control required"
                                            value={contactDetails.contact_name}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="Name"
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group"><label>Email Address</label>
                                        <input
                                            type="text"
                                            name="contact_email"
                                            className="form-control required"
                                            value={contactDetails.contact_email}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="Email"
                                        /></div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group"><label>Mobile</label>
                                        <input
                                            type="text"
                                            name="contact_mobile"
                                            className="form-control required"
                                            value={contactDetails.contact_mobile}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="Mobile"
                                        /></div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group"><label>Message</label>
                                        <textarea
                                            name="contact_message"
                                            className="form-control required"
                                            value={contactDetails.contact_message}
                                            onChange={(e) => onTodoChange(e)}
                                        /></div>
                                </div>
                                <div class="col-lg-6"><button type="button" class="btn btn-primary btn-medium btn btn-primary" onClick={contactusProcess}>Submit</button></div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="contact-address-section mb-3">
                                <div class="section-title">
                                    <h3>MT Resin</h3>
                                </div>

                                <div class="address-contact">
                                    <ul>
                                        <li>
                                            <a href="https://maps.app.goo.gl/SWGYFWYXgTMhmH75A" target="new"><i class="ri-map-pin-2-line mr-5"></i><span>{settingData.address}</span> </a>
                                        </li>
                                        <li>
                                            <a href={"mailto:" + settingData.admin_support_email}><i class="ri-mail-line mr-5"></i><span>{settingData.admin_support_email}</span> </a>
                                        </li>
                                        <li>
                                            <a href={"tel:" + settingData.admin_mobile}><i class="ri-phone-line mr-5"></i><span>{settingData.admin_mobile}</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default ContactUs