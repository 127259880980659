import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from 'react-toastify';
import Home from './Containers/Home';
import Address from './Containers/Account/address';
import Order from './Containers/Account/orders';
import OrderDetail from './Containers/Account/order_detail';
import Collection from './Containers/Collection';
import Blog from './Containers/Blog';
import BlogDetail from './Containers/BlogDetail';
import Pages from './Containers/Pages';
import AccountOverview from './Containers/Account/account_overview';
import Orders from './Containers/Account/orders';
import Wishlist from './Containers/Account/wishlist';
import HelpandSupport from './Containers/Account/helpsupport';
import Profile from './Containers/Account/profile';
import Productdetails from './Containers/Productdetails';
import ContactUs from './Containers/ContactUs';
import Search from './Containers/Search';
import Category from './Containers/Category'
import Thankyou from './Containers/Order/thank_you';
import Cancel from './Containers/Order/cancel';
import Checkout from './Containers/Cart/checkout';
import Error404 from './Containers/Error404/error_404';
function App() {
  return (
    <div className="App">
      <ToastContainer
        position="bottom-center"
      />
      <Router>
        <Routes> 

          <Route path="/" activeClassName="active" element={<Home />} />

          {/* Account Routes Section */}
          <Route exact path="/account/account-overview" activeClassName="active" element={<AccountOverview />} />
          <Route exact path="/account/profile" activeClassName="active" element={<Profile />} />
          <Route exact path="/account/address" activeClassName="active" element={<Address />} />
          <Route exact path="/account/wishlist" activeClassName="active" element={<Wishlist />} />
          <Route exact path="/account/orders" activeClassName="active" element={<Orders />} />
          <Route path="/account/order-details/:id"  activeClassName="active" element={<OrderDetail />} />
          <Route exact path="/account/help-and-support" activeClassName="active" element={<HelpandSupport />} />

          <Route path="/contact-us" activeClassName="active" element={<ContactUs />} />
          <Route path="/search" activeClassName="active" element={<Search />} />
          <Route path="/category" activeClassName="active" element={<Category />} />
          <Route path="/checkout" activeClassName="active" element={<Checkout />} />

          <Route path="/order" activeClassName="active" element={<Order />} />
          <Route path="/error_404" activeClassName="active" element={<Error404 />} />



          <Route exact path='/collection/:type/:slug?' activeClassName="active" element={<Collection />} />
          <Route exact path='/product/:slug' activeClassName="active" element={<Productdetails />} />
          <Route path="/blog" activeClassName="active" element={<Blog />} />
          <Route path="/blog-detail" activeClassName="active" element={<BlogDetail />} />
          <Route path="/:slug" activeClassName="active" element={<Pages />} />
          <Route path="/cancel" activeClassName="active" element={<Cancel />} />
          <Route path="/thankyou/:orderId" activeClassName="active" element={<Thankyou />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
